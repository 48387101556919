
/*=============================================
=            Sidebar            =
=============================================*/


/*-- Sidebar --*/
.sidebar {
    margin-bottom: 50px;
    padding: 30px;

    background-color: #F7F8F9;
    &.sidebar-two {
        padding: 0;

        background-color: transparent;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
/*-- Sidebar Title --*/
.sidebar-title {
    font-size: 24px;
    font-weight: 700;

    margin-top: -4px;
    margin-bottom: 30px;
}
/*-- Sidebar Search --*/
.sidebar-search {
    & form {
        display: flex;

        border: 1px solid #E5E6E7;
        & input {
            font-size: 18px;

            flex: 1 0 calc(100% - 40px);

            max-width: calc(100% - 40px);
            height: 40px;
            padding: 5px 15px;

            color: $body-text-color;
            border: none;
            background-color: transparent;
        }
        & button {
            display: flex;
            flex: 1 0 40px;
            justify-content: center;

            max-width: 40px;
            height: 40px;
            padding: 0;

            border: none;
            background-color: transparent;
            & i {
                font-size: 20px;
            }
            &:hover {
                color: $theme-color--default;
            }
        }
    }
}
/*-- Sidebar List --*/
.sidebar-list {
    & li {
        margin-bottom: 10px;
        padding-bottom: 10px;

        border-bottom: 1px solid #E5E6E7;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            border-bottom: 0 solid transparent;
        }
        & a {
            font-size: 20px;

            display: flex;
            align-items: center;
            & img {
                width: 25px;
                margin-right: 15px;
            }
            & i {
                font-size: 24px;

                margin-right: 15px;
            }
            &:hover {
                color: $theme-color--default;
            }
        }
    }
}
/*-- Sidebar Blog --*/
.sidebar-blog {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 15px;
    padding-bottom: 15px;

    border-bottom: 1px solid #E5E6E7;
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;

        border-bottom: 0;
    }
    & .image {
        flex: 1 0 80px;

        max-width: 80px;
        & img {
            width: 100%;
        }
    }
    & .content {
        flex: 1 0 calc(100% - 80px);

        max-width: calc(100% - 80px);
        padding-left: 15px;
        & h5 {
            font-size: 18px;
            line-height: 1.2;

            margin-bottom: 5px;
            & a {
                &:hover {
                    color: $theme-color--default;
                }
            }
        }
        & span {
            font-size: 13px;
            line-height: 18px;

            display: block;
        }
    }
}
/*-- Sidebar Tags --*/
.sidebar-tag {
    display: flex;
    flex-wrap: wrap;

    margin: -5px;
    & li {
        padding: 5px;
        & a {
            font-size: 14px;

            display: block;

            padding: 3px 15px;

            border: 1px solid #E5E6E7;
            &:hover {
                color: $white;
                border-color: $theme-color--default;
                background-color: $theme-color--default;
            }
        }
    }
}


/*=====  End of Sidebar  ======*/

