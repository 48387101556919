
/*=============================================
=            About            =
=============================================*/

.about-content {
    &__text {
        width: 100%;
        max-width: 520px;
    }
}

/*=====  End of About  ======*/