
/*=============================================
=            contact            =
=============================================*/


/*-- Map --*/
.contact-map {
    iframe {
        width: 100%;
        height: 400px;

        border: 1px solid #DDD;
    }
}

/*-- Contact Information --*/
.contact-information {
    @media #{$tablet-device} {
        margin-bottom: 50px;
    }

    @media #{$large-mobile} {
        margin-bottom: 30px;
    }

    & h4 {
        font-weight: 700;
    }
    & ul {
        & li {
            display: flex;
            flex-wrap: wrap;

            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            & .icon {
                width: 50px;
                & i {
                    font-size: 30px;
                }
            }
            & .text {
                max-width: calc(100% - 50px);
                & span,
                & a {
                    line-height: 24px;

                    display: block;

                    max-width: 230px;

                    &:hover {
                        color: $theme-color--default;
                    }
                }
            }
        }
    }
}

/*-- Contact Form --*/
.contact-form {
    & h4 {
        font-weight: 700;
    }
    & input {
        width: 100%;
        height: 50px;
        padding: 5px 20px;

        border: 1px solid #EEEEEE;
    }
    & textarea {
        width: 100%;
        height: 120px;
        padding: 10px 20px;

        resize: none;

        border: 1px solid #EEEEEE;
    }
    & input[type="submit"],
    & button,
    & .submit {
        font-weight: 700;

        width: auto;
        height: 50px;
        margin-top: 15px;
        padding: 5px 30px;

        text-transform: uppercase;

        color: $white;
        border: none;
        background-color: $theme-color--default;
        &:hover {
            background-color: $heading-text-color;
        }
    }
}

.form-message {
    line-height: 2;
}


/*=====  End of contact  ======*/

