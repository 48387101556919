#login-form{
    margin: 0 auto;
    color: green;

    label{
        color: #174e81;
    }
}

.login-form{
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%); 
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%); 

    .login-title{
        text-align: center;
    }

    .versao{
        color: gray;
        font-size: 10px;
        text-align: center;
        width: 100%;
    }
}