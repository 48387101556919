
/*=============================================
=            CTA            =
=============================================*/

.cta-block {
    &--shadow {
        box-shadow: -3px 0 51.84px 2.16px rgba(0, 0, 0, 0.23);
    }

    &--default-color {
        p {
            color: $theme-color--default;
        }
    }
    &__inner {
        padding-right: 15px;
        padding-left: 15px;
    }
    &__light-text {
        font-size: 36px;
        font-weight: 400;
        line-height: 1;
        @media #{$desktop-device} {
            font-size: 30px;
        }
        @media #{$extra-small-mobile} {
            font-size: 25px;
        }
        span {
            font-size: 48px;
            font-weight: 700;
            line-height: 1;
            @media #{$desktop-device} {
                font-size: 40px;
            }
            @media #{$extra-small-mobile} {
                font-size: 35px;
            }
        }
    }

    &__semi-bold-text {
        font-size: 42px;
        font-weight: 600;
        line-height: 1;
        @media #{$desktop-device} {
            font-size: 35px;
        }
        @media #{$extra-small-mobile} {
            font-size: 30px;
        }

        &--medium {
            font-size: 36px;
        }
    }

    &__bold-text {
        font-size: 56px;
        font-weight: 700;
        line-height: 1;
        @media #{$desktop-device} {
            font-size: 45px;
        }
        @media #{$extra-small-mobile} {
            font-size: 35px;
        }
    }

    &--bg {
        background-repeat: no-repeat;
        background-size: cover;
    }
}



/*=====  End of CTA  ======*/

