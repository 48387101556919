
/*=============================================
=            Button            =
=============================================*/

.default-btn {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;

    display: inline-block;

    padding: 15px 20px;

    color: $white;
    border: none;
    background: none;
    background: $theme-color--default;

    &.btn-sm{
        font-size: 15px;
        padding: 10px 15px;
    }

    &.second-color{
        background: $theme-color--black;
    }

    &.third-color{
        background: $success;
    }

    &--hero-slider {
        font-size: 28px;

        padding: 17px 25px;
        @media #{$tablet-device} {
            font-size: 25px;

            padding: 15px 20px;
        }
        @media #{$large-mobile} {
            font-size: 22px;

            padding: 15px 20px;
        }
    }

    &:hover {
        color: $white;
        background: $theme-color--black;
    }
}



.see-more-link {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;

    transition: $transition--default;
    text-decoration: underline;

    color: $theme-color--black;
    &:hover {
        text-decoration: underline;

        color: $theme-color--default;
    }

    &--color {
        color: $theme-color--default;
        &:hover {
            color: $theme-color--black;
        }
    }
}

/*=====  End of Button  ======*/

