#layout{
  .wrapper{
    // margin-top: 100px;  
  }

  .brand-logo img{
    background-color: white;
    margin: 5px;
  }

  .main-nav-menu > ul > li > a {
    color: white;
  }

  .main-nav-menu > ul > li > a:after {
    background-color: white;
  }
}

/* tela grande */
@media (min-width: 992px){
  .wrapper{
      margin-top: 100px;
  }
}

.main-nav-menu > ul > li > a.active:after {
width: 100%!important;
  background-color: white;
}

.alerta{
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: red;
  border-radius: 5px;
  top: 25px;
  position: absolute;
  text-indent: -9999px;   
}