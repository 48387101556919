
/*=============================================
=            footer            =
=============================================*/


/* footer widget */

.footer-widget {
    &__text {
        width: 310px;
        max-width: 100%;

        color: #DDD;
    }
    &__title {
        font-weight: 700;

        color: #DDD;
    }
    &__menu {
        li {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: #DDD;
                &:hover {
                    color: $theme-color--default;
                }
            }
        }
    }

    .social-icons {
        li {
            display: inline-block;

            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 14px;
                line-height: 30px;

                display: block;

                width: 30px;
                height: 30px;
                padding: 0 5px;

                text-align: center;

                color: #DDD;
                border: 1px solid;
                &:hover {
                    color: $theme-color--default;
                }
            }
        }
    }
}


/* footer contact */

.single-footer-contact {
    display: flex;

    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    &__icon {
        flex-basis: 20px;
        i {
            color: #DDD;
        }
    }

    &__text {
        line-height: 1.2;

        flex-basis: calc(100% - 20px);

        margin-left: 15px;

        color: #DDD;
    }
}



/* copyright */
.copyright-text {
    color: #FAFAFA;
}

/*=====  End of footer ======*/