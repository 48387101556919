
/*=============================================
=            Service            =
=============================================*/

.service-banner {
    & > img {
        display: block;

        @media #{$tablet-device,
        $large-mobile} {
            display: inline-block;

            max-width: 100%;
        }
    }
    @media #{$tablet-device,
    $large-mobile} {
        text-align: center;
    }
}

.single-service {
    &__image {
        a {
            display: block;
            img {
                width: 100%;
            }
        }
    }

    &__content {
        font-weight: 700;
    }
}


/* service grid item */


.service-grid-item {
    &__image {
        position: relative;

        margin-bottom: 50px;
        .icon {
            line-height: 70px;

            position: absolute;
            bottom: -35px;
            left: 20px;

            width: 70px;
            height: 70px;

            transition: $transition--default;
            text-align: center;

            background-color: #393939;

            i {
                font-size: 40px;

                color: $white;
            }
        }

        a {
            display: block;
            img {
                width: 100%;

                transition: $transition--default;
                transition-duration: 0.6s;
            }
        }
    }

    &__image-wrapper {
        overflow: hidden;
    }

    &__content {
        .title {
            a {
                font-size: 24px;
                font-weight: 700;

                transition: $transition--default;
                &:hover {
                    color: $theme-color--default;
                }

                @media #{$extra-small-mobile} {
                    font-size: 22px;
                }
            }
        }

        .subtitle {
            margin-bottom: 15px;
        }
    }

    &:hover {
        .service-grid-item__image {
            .icon {
                background-color: $theme-color--default;
            }

            img {
                transform: scale(1.1);
            }
        }

        .service-grid-item__content {
            .see-more-link {
                color: $theme-color--default;
            }
        }
    }
}


.service-gallery {
    &__wrapper {
        .slick-arrow {
            font-size: 30px;
            line-height: 50px;

            position: absolute;
            z-index: 2;
            top: 50%;

            visibility: hidden;

            width: 50px;
            height: 50px;
            padding: 0;

            transform: translateY(-50%);

            opacity: 0;
            color: #FFFFFF;
            border: none;
            background: none;
            background-color: rgba(255, 255, 255, 0.6);

            @media #{$tablet-device,
            $large-mobile} {
                display: none;
            }

            &.slick-next {
                right: 80px;
                @media #{$laptop-device,
                $desktop-device} {
                    right: 15px;
                }
            }

            &.slick-prev {
                left: 80px;
                @media #{$laptop-device,
                $desktop-device} {
                    left: 15px;
                }
            }
        }

        &:hover {
            .slick-arrow {
                visibility: visible;

                opacity: 1;
                &.slick-next {
                    right: 60px;
                    @media #{$laptop-device,
                    $desktop-device} {
                        right: 15px;
                    }
                }
                &.slick-prev {
                    left: 60px;
                    @media #{$laptop-device,
                    $desktop-device} {
                        left: 15px;
                    }
                }
            }
        }
    }
}

.service-details {
    h3,
    h4 {
        font-weight: 700;
    }
}

/*=====  End of Service  ======*/

